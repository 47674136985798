import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
      primary: {
          main: '#212121'
      },
    //   type: 'dark',
    },
});

export default theme;